import React from 'react'
import { createPortal } from 'react-dom'

let modalRoot

if (typeof document !== 'undefined') {
  modalRoot = document ? document.body : null
}

export default class ModalBase extends React.Component {
  constructor(props) {
    super(props)

    if (typeof document !== 'undefined') {
      this.el = document ? document.createElement('div') : null
    }
    
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el)
  }

  render() {
    // this is necessary for the gatsby build step 
    // we do not want to create a portal if this.el is null
    return !this.el ? null : createPortal(
      this.props.children,
      this.el
    )
  }
}