import React from "react"
import Modal from './Modal'

const getDiffDays = diff => diff === 1 ? 'tomorrow night' : `in ${diff} days`
const getLessonUri = (study, filename) => {
  return 'https://s3.amazonaws.com/13one.org/files/' + study + '/' + filename + '.pdf'
}

export default class StudyListItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false
    }
  }

  openLesson = () => {
    const { s3Path, lesson } = this.props

    // if there are alternate lessons, show a modal to choose which one
    window.open(getLessonUri(s3Path, lesson.slug || `lesson${lesson.lesson}`), '_blank')
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  closeModal = e => {
    e.stopPropagation() // important to prevent bubbling events from modal
    
    this.setState({ showModal: false })
  }

  render () {
    const { lesson, s3Path, isDiscussionDay, isNextLesson, nextLessonDayDiff } = this.props
    const { showModal } = this.state

    return (
      <div onClick={lesson.alternates ? this.openModal : this.openLesson} className={(isDiscussionDay || isNextLesson) ? 'next-lesson' : ''}>
        <>
          <div>
            {(isDiscussionDay || isNextLesson) && 
              <div className="discussing-reminder">Discussing {isDiscussionDay ? 'tonight' : getDiffDays(nextLessonDayDiff)}</div>
            }
          </div>
          <div className="list-item-icon">
            <span className="circle">{lesson.lesson}</span>
          </div>
          <div className="list-item-content">
            <div className="title">{lesson.title}</div>
            <div className="subtitle">{lesson.passage}</div>
          </div>
          <Modal show={showModal} onClose={this.closeModal}>
            <header className="modal__header">
              <h2 className="modal__title" id="lesson-type-modal-title">
                Choose a Lesson Style
              </h2>
              <button onClick={this.closeModal} className="modal__close" aria-label="Close modal" data-modal-id="lesson-type-modal-1"></button>
            </header>
            <main className="modal__content" id="lesson-type-modal-content">
              <p>
                This lesson has two variations. Choose the one you'd like to download.
              </p>
            </main>
            <footer className="modal__footer">
              <a href={`https://s3.amazonaws.com/13one.org/files/${s3Path}/lesson${lesson.lesson}-mid-intensity.pdf`} className="modal__btn modal__btn-primary" target="blank">Light-Mid Intensity</a>
              <a href={`https://s3.amazonaws.com/13one.org/files/${s3Path}/lesson${lesson.lesson}-high-intensity.pdf`} className="modal__btn modal__btn-primary" target="blank">High Intensity</a>
            </footer>
          </Modal>
        </>
      </div> 
    )
  }
}
