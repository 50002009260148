import React from "react"
import { DateTime, Interval } from 'luxon'

import StudyListItem from './StudyListItem'

const nowTime = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) // Modify "now" to test date calculation for future dates

export default class StudyList extends React.Component {
  render () {
    const { study } = this.props

    // these dates make it easy to highlight the current or upcoming lesson
    const discussionNowDiffs = study.lessons.map(l => DateTime.fromISO(l.discussionDay).diff(nowTime).as('days'))
    let nextLessonIdx
    let nextLessonDiff

    // find the first date diff which is positive (future date)
    discussionNowDiffs.forEach((diff, i) => {
      if (!nextLessonIdx && diff > 0) {
        nextLessonDiff = parseInt(diff)
        nextLessonIdx = i
      }
    })

    return (
      <div id="lesson-list">
        {study.lessons.map((l, i) => {
          const isDiscussionDay = Math.abs(DateTime.fromISO(l.discussionDay).diff(nowTime, 'hours').hours) < 24

          return <StudyListItem
            key={i}
            lesson={l} 
            s3Path={study.s3Path}
            isDiscussionDay={isDiscussionDay}
            isNextLesson={i === nextLessonIdx}
            nextLessonDayDiff={nextLessonDiff} />
        })}
      </div>
    )
  }
}
