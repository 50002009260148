import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import StudyList from "../components/StudyList"

export default class Study extends React.Component {
  showModal = () => {
    this.setState({ showModal: true })
  }

  render () {
    const { data } = this.props
    const study = data.studiesJson

    return (
      <Layout>
        <SEO title={study.name} />
        <div id="logo-container">
          <h1>{study.name}</h1>
        </div>
        <div id="nav-container">
          <div id="lesson-page" className="item highlighted full-width">Lessons</div>
        </div>
        <StudyList study={study} />
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    studiesJson(slug: { eq: $slug } ) {
      name
      slug
      s3Path
      lessons {
        title
        slug
        lesson
        passage
        discussionDay
        alternates
      }
    }
  }
`