import React from 'react'
import ModalBase from './ModalBase'

export default class Modal extends React.Component {
  render() {
    const { show, children, onClose } = this.props
    
    return (
      <ModalBase>
        <div className="modal micromodal-slide is-open" id="lesson-type-modal-1" aria-hidden="false">
          {show &&
            <div onClick={onClose} className="modal__overlay" tabIndex="-1">
              <div onClick={e => e.stopPropagation()} className="modal__container" role="dialog" aria-modal="true" aria-labelledby="lesson-type-modal-title">
                {children}
              </div>
            </div>
          }
        </div>
      </ModalBase>
    )
  }
}